import { useMutation, useQueryClient } from 'react-query';

import Api from '../../api/api';

export const disApproveTopic = async (topicId: string) => {
  const [response, bodyOrError] = await Api.delete(`topics/${topicId}`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useDispproveTopic = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'disApproveTopic',
    (data: { topicId: string }) => {
      return disApproveTopic(data.topicId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['suggestCategoryTopics']);
      },
    },
  );
};
