import { AWS_ACL } from '../../types/Media';
import { MediaType, MediaUploadOptions } from '../media';

import { checkImageFile, resizeImage } from './helpers';
import { s3Upload } from './s3';

const NUMBER_OF_TRIES = 3;

const prepareMediaFiles = async (options: MediaUploadOptions) => {
  const { resource, dimensions } = options;

  let mediaFiles = [resource];
  const isImage = checkImageFile(resource);

  if (isImage) {
    mediaFiles = await resizeImage(resource, {
      dimensions,
    });
  }

  const totalUploadSize = mediaFiles.reduce((accumulator, currentValue) => accumulator + currentValue.size, 0);

  return { mediaFiles, totalUploadSize, isImage };
};

export const processAndUploadFileToS3 = async (options: MediaUploadOptions) => {
  const { mediaFiles, totalUploadSize, isImage } = await prepareMediaFiles(options);

  const getResourceUrl = async (currentValue: { blob: File | Blob }) => {
    // # Try to upload the file for ${NUMBER_OF_TRIES} times
    for (let i = 0; i < NUMBER_OF_TRIES; i++) {
      try {
        const url = await s3Upload({
          ...options,
          totalUploadSize,
          resource: currentValue.blob,
          type: (currentValue.blob.type as MediaType) || 'image/jpeg',
          acl: AWS_ACL.PUBLIC_READ_WRITE, // another option: AWS_ACL.PRIVATE,
        });

        return url;
      } catch (error) {
        if (i === NUMBER_OF_TRIES - 1) {
          throw error;
        }
      }
    }

    throw new Error('Maximum Upload Tries exceeded');
  };

  const uploadResults = [];

  for (const mediaFile of mediaFiles) {
    const resourceUrl = await getResourceUrl({ blob: mediaFile });
    uploadResults.push(resourceUrl);
  }

  return { uploadResults, isImage };
};
