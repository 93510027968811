import React from 'react';

import { Route, Routes } from 'react-router';

import CategoryCreate from './CategoryCreate';
import CategoryEdit from './CategoryEdit';
import CategoryList from './CategoryList';
import FeaturedCategoryList from './FeaturedCategoryList';

function CategoryModule() {
  return (
    <Routes>
      <Route path="" element={<CategoryList />} />
      <Route path="create" element={<CategoryCreate />} />
      <Route path=":id" element={<CategoryEdit />} />
      <Route path="featured" element={<FeaturedCategoryList />} />
    </Routes>
  );
}

export default CategoryModule;
