import Cookies from 'js-cookie';

import { Defaults } from '../../constants';
import { Deferred } from '../util/Deferred';

import { HttpMethod, StaticUrls, setAccessToken } from './api';

const ACCESS_TOKEN_HEADER_KEY = 'Authorization';

const queue: Deferred<boolean>[] = [];

const _doRefresh = async (deferred: Deferred<boolean>) => {
  let body;
  const refreshToken = Cookies.get(Defaults.REFRESH_TOKEN_COOKIE_KEY);
  let refreshOptions: any = { method: HttpMethod.GET, headers: { [ACCESS_TOKEN_HEADER_KEY]: 'Bearer ' + refreshToken } };
  const response = await fetch(StaticUrls.REFRESH, refreshOptions);
  try {
    body = await response.json();
  } catch (error) {}
  if (response.ok) {
    setAccessToken(body.accessToken, body.refreshToken);

    queue.forEach(d => d.resolve(true));
    queue.length = 0;
  } else {
    queue.forEach(d => d.resolve(false));
    queue.length = 0;
  }
};

export const refreshToken = (): Promise<boolean> => {
  const deferred = new Deferred<boolean>();
  queue.push(deferred);

  if (queue.length === 1) {
    _doRefresh(deferred);
  }

  return deferred.promise;
};
