import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getCategory = async (categoryId: string) => {
  const [response, bodyOrError] = await Api.get(`categories/${categoryId}`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetCategory = (categoryId: string) =>
  useQuery(['getCategory', categoryId], () => getCategory(categoryId), {
    enabled: categoryId !== 'new' || !!categoryId,
  });
