import { useMutation } from 'react-query';

import Api from '../../api/api';

export type CategoryCreateDTO = {
  title: string;
  media?: any;
  published: boolean;
};

export const createCategory = async (params: CategoryCreateDTO) => {
  const [response, bodyOrError] = await Api.post('categories', params);

  if (!response.ok) {
    throw bodyOrError;
  }
  return bodyOrError;
};

export const useCreateCategory = () => useMutation('createCategory', createCategory);
