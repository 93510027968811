import { useMutation, useQueryClient } from 'react-query';

import Api from '../../api/api';
import { TopicDto } from '../../topic/hooks';

export type CategoryUpdateDTO = {
  id?: string;
  title?: string;
  media?: any;
  published?: boolean;
  featured?: boolean;
  undelete?: boolean;
  topic?: TopicDto[];
};

export const updateCategory = async (categoryId: string, params: CategoryUpdateDTO) => {
  const [response, bodyOrError] = await Api.patch(`categories/${categoryId}`, params);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'updateCategory',
    (data: { categoryId: string; params: CategoryUpdateDTO }) => {
      return updateCategory(data.categoryId, data.params);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['getCategory']);
        queryClient.invalidateQueries(['getCategoriesByPage']);
        queryClient.invalidateQueries(['getCategoriesByFeatured']);
      },
    },
  );
};
