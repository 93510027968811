import { useMutation } from 'react-query';

import { Media } from '../../../types/Media';
import { MutationOptions } from '../../../types/Query';
import Api from '../../api/api';

export type MediaCreateDto = {
  filename: string;
  originalUrl?: string;
  smallUrl: string;
  mediumUrl: string;
  largeUrl: string;
};

const createMedia = async (params: MediaCreateDto) => {
  const [response, bodyOrError] = await Api.post('media', params);
  if (!response?.ok || !bodyOrError) {
    throw bodyOrError;
  }
  return bodyOrError;
};

const usePostMedia = (options?: MutationOptions<Media, MediaCreateDto>) => {
  return useMutation({
    ...options,
    mutationKey: ['createMedia'],
    mutationFn: createMedia,
  });
};

export default usePostMedia;
