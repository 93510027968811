import { useQuery } from 'react-query';

import Api from '../../api/api';

export type RolesDTO = {
  id: number;
  name: string;
};

export const getAllRoles = async () => {
  const [response, bodyOrError] = await Api.get(`roles`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetAllRoles = () => useQuery('getAllRoles', getAllRoles, { initialData: [] });
