import React from 'react';

import { Route, Routes } from 'react-router';

import UserEdit from './UserEdit';
import UserList from './UserList';

function UserModule() {
  return (
    <Routes>
      <Route path='' element={<UserList />}/>
      <Route path=':id' element={<UserEdit />}/>
    </Routes>
  );
}

export default UserModule;
