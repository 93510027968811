import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getUsersCount = async (search: string) => {
  const [response, bodyOrError] = await Api.get(`users/count`, { search });

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetUsersCount = ({ search = '' }: { search?: string }) => useQuery(['getUsersCount', search], () => getUsersCount(search));
