import { useEffect, useContext, useState, useCallback } from 'react';

import { Formik, FormikErrors } from 'formik';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';

import { Typography, Card, Button, Grid, styled, Checkbox, FormControlLabel } from '@mui/material';

import { SubmitButton } from '../../../components/buttons';
import { FormContainer } from '../../../components/forms';
import { BrowseUpload, TextField } from '../../../components/inputs';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { ErrorMessages } from '../../../constants';
import { CategoryCreateDTO, useCreateCategory } from '../../../lib/category/hooks';
import useMediaUpload from '../../../lib/media/hooks/useMediaUpload';
import { checkFileSize } from '../../../lib/util/helpers';
import { Media } from '../../../types/Media';

type CategoryFormType = {
  id: string;
  title: string;
  media?: any;
  published: boolean;
};

const CategoryCreateSchema = Yup.object().shape({
  title: Yup.string().required(ErrorMessages.REQUIRED_CATEGORY_NAME),
  media: Yup.mixed().required(ErrorMessages.REQUIRED_PHOTO),
});

function CategoryCreate() {
  const navigate = useNavigate();
  const createCategory = useCreateCategory();

  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [photosErrorMsg, setPhotosErrorMsg] = useState<string>('');
  const [photoMedia, setPhotoMedia] = useState<Media | null>(null);
  const { upload, error: uploadError } = useMediaUpload({
    onSuccess: (data) => {
      setPhotoMedia(data);
    },
    onError: () => {
      setAlertSnackbar({
        message: ErrorMessages.SERVER_ERROR,
        severity: 'error',
      });
      setPhotosErrorMsg(ErrorMessages.SERVER_ERROR);
    },
  });

  useEffect(() => {
    if (!uploadError) return;
    setAlertSnackbar({
      message: ErrorMessages.SERVER_ERROR,
      severity: 'error',
    });
    setPhotosErrorMsg(ErrorMessages.SERVER_ERROR);
  }, [setAlertSnackbar, uploadError]);

  useEffect(() => {
    setSubmitting(createCategory.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!createCategory.isLoading) {
      if (createCategory.error) {
        message = (createCategory.error as Error).message;
      } else if (createCategory.data) {
        message = `${createCategory.data.title} created successfully.`;
      }
    }

    if (createCategory.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/categories');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [navigate, createCategory.data, createCategory.error, createCategory.isLoading, setAlertSnackbar]);

  const handlePhotoUpload = useCallback(
    async (
      event: React.ChangeEvent<HTMLInputElement>,
      setFieldValue: (field: string, value: any, shouldValidate?: boolean) => Promise<void | FormikErrors<CategoryFormType>>,
    ) => {
      const files = event.target.files;

      if (files && files[0]) {
        const file = files[0];
        try {
          checkFileSize(file);
          setPhotosErrorMsg('');

          // Upload photo to media table and S3
          await upload(files[0]);
        } catch (error) {
          let errorMessage;
          if (error instanceof Error) errorMessage = error.message;
          else errorMessage = ErrorMessages.SERVER_ERROR;

          setPhotosErrorMsg(errorMessage);
          setAlertSnackbar({
            message: errorMessage,
            severity: 'error',
          });
        }

        setFieldValue('media', file);
      }
    },
    [setAlertSnackbar, upload],
  );

  const handleSubmit = async (values: CategoryFormType) => {
    let newCategory: CategoryCreateDTO = {
      title: values.title,
      media: photoMedia,
      published: values.published,
    };

    // save new category
    createCategory.mutate(newCategory);
  };

  let ready = false;
  let initialValues: CategoryFormType = { id: '', title: '', media: null, published: false };

  ready = true;

  const setInitValue = (value?: string | boolean) => value || ' ';

  return (
    <Container>
      <TopBar>
        <Typography variant="h5">Category</Typography>
      </TopBar>

      <StyledCard>
        {!ready && <Spinner />}
        {ready && initialValues && (
          <Formik initialValues={initialValues} validationSchema={CategoryCreateSchema} validateOnBlur={true} onSubmit={handleSubmit}>
            {({ values, errors, touched, setFieldValue, handleChange, handleBlur }) => (
              <FormContainer>
                <Grid container rowSpacing={2}>
                  <Grid container spacing={2} sx={{ mb: photoMedia ? '30px' : '0' }}>
                    <Grid item xs={8}>
                      <BrowseUpload
                        error={touched.media && !!errors.media}
                        fileLabel="Upload Photo"
                        name="media"
                        handleFileInput={(event) => {
                          handlePhotoUpload(event, setFieldValue);
                        }}
                        handleBlur={handleBlur}
                        disabled={isSubmitting}
                        errorMsg={photosErrorMsg}
                        helperText={errors.media && touched.media ? errors.media : null}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      {photoMedia ? (
                        <>
                          <Typography>Uploaded Photo</Typography>
                          <img src={photoMedia?.smallUrl} alt="" />
                        </>
                      ) : null}
                    </Grid>
                  </Grid>

                  <Grid container spacing={2}>
                    <Grid item xs={8}>
                      <TextField
                        error={touched.title && !!errors.title}
                        type="text"
                        name="title"
                        label="Name"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.title}
                        helperText={setInitValue(touched.title && errors.title)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            id="published"
                            name="published"
                            checked={values.published}
                            value={values.published}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        }
                        label="Enable"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <ButtonContainer>
                  <Buttons>
                    <SubmitButton type="submit" disabled={isSubmitting} loading={isSubmitting} variant="contained">
                      Save
                    </SubmitButton>
                    <Button onClick={() => navigate(-1)} type="button">
                      Cancel
                    </Button>
                  </Buttons>
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
    </Container>
  );
}

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
});

const StyledCard = styled(Card)({
  display: 'flex',
});

const ButtonContainer = styled('div')({
  marginTop: '20px',
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',

  '& > *': {
    margin: '5px',
  },
});

export default CategoryCreate;
