import { useMutation, useQueryClient } from 'react-query';

import { Category } from '../../../types/Category';
import Api from '../../api/api';

export const deleteCategory = async (categoryId: string) => {
  const [response, bodyOrError] = await Api.delete(`categories/${categoryId}`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useDeleteCategory = () => {
  const queryClient = useQueryClient();

  return useMutation<Category, Error, string>('deleteCategory', deleteCategory, {
    onSuccess: () => {
      queryClient.invalidateQueries('getCategoriesByPage');
      queryClient.invalidateQueries(['getCategoriesCount']);
      queryClient.invalidateQueries(['getCategoriesByFeatured']);
    },
  });
};
