import { useNavigate, useParams } from 'react-router';

import { Typography, Card, styled, Grid, Button } from '@mui/material';

import { ConfirmDialog } from '../../../components/dialogs';
import { useDeleteReportedPostById, useGetReportedPostById } from '../../../lib/post/hooks';

function ReportedPostEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const reportedPostId = id ?? '';
  const getReportedPost = useGetReportedPostById(reportedPostId);
  const data = getReportedPost.data;
  const deleteReportedPost = useDeleteReportedPostById();
  const author =
    data?.post.author.firstName && data?.post.author.lastName
      ? `${data?.post.author.firstName} ${data?.post.author.lastName}`
      : data?.post.author.username;
  const reporter =
    data?.reporter.firstName && data?.reporter.lastName
      ? `${data?.reporter.firstName} ${data?.reporter.lastName}`
      : data?.reporter.username;

  const handleDelete = async (id: string) => {
    await deleteReportedPost.mutate(id);
    navigate(-1);
  };

  return (
    <Container>
      <TopBar>
        <Typography variant="h5">Post Detail</Typography>
      </TopBar>

      <StyledCard>
        <Grid container rowSpacing={4} mb={4}>
          <Grid item xs={12}>
            <Typography>Reported Date: {new Date(data?.created).toLocaleDateString()}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Title of Reported Article: {data?.post.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Author's Name: {author}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Reporter: {reporter}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>Reason: {data?.reason}</Typography>
          </Grid>
        </Grid>
        <ButtonContainer>
          <Buttons>
            <Button onClick={() => navigate(-1)} type="button">
              Cancel
            </Button>
            <ConfirmDialog
              trigger={(open) => (
                <Button onClick={open} type="button" variant="contained">
                  Remove
                </Button>
              )}
              title="Delete Reported Post"
              description="Are you sure you want to delete this reported post?"
              cancelBtnText={'Cancel'}
              submitBtnText={'Submit'}
              onConfirm={async (close) => {
                await handleDelete(data?.id);
                close();
              }}
            ></ConfirmDialog>
          </Buttons>
        </ButtonContainer>
      </StyledCard>
    </Container>
  );
}

const Container = styled('div')({
  padding: '20px',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
});

const StyledCard = styled(Card)({
  padding: '40px',
});

const ButtonContainer = styled('div')({
  marginTop: '40px',
  alignSelf: 'flex-end',
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  '& > *': {
    margin: '5px',
  },
});

export default ReportedPostEdit;
