import { Navigate } from 'react-router';

import useUser from '../lib/user/useUser';

function LoginCheck() {
  const [user] = useUser();
  if (user) {
    // put your default redirect here
    if (user.roles && user.roles.find((r) => r.name === 'Admin')) {
      return <Navigate to="/cms" />;
    } else {
      return <Navigate to="/login" />;
    }
  } else if (user === null) {
    return <Navigate to="/login" />;
  }
  return null;
}

export default LoginCheck;
