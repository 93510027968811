import { useCallback, useState } from 'react';

import { ImageDimensionKey, ImageDimensions } from '../../../constants';
import { Media } from '../../../types/Media';
import { MutationOptions } from '../../../types/Query';
import { checkImageFile, getFileImageHeightAndWidth } from '../../util/helpers';
import { processAndUploadFileToS3 } from '../../util/processAndUploadFileToS3';

import usePostMedia, { MediaCreateDto } from './usePostMedia';

function useMediaUpload(options?: MutationOptions<Media, MediaCreateDto>) {
  const [isUploading, setIsUploading] = useState(false);
  const [errorS3Upload, setErrorS3Upload] = useState<Error | null>(null);
  const {
    data,
    mutateAsync: postMedia,
    error: errorResponse,
  } = usePostMedia({
    ...options,
    onSettled: () => {
      setIsUploading(false);
    },
  });
  const upload = useCallback(
    async (file: File) => {
      try {
        setErrorS3Upload(null);
        setIsUploading(true);
        const isImage = checkImageFile(file);
        let originalImageDimension = ImageDimensions[ImageDimensionKey.DEFAULT];
        if (isImage) {
          originalImageDimension = await getFileImageHeightAndWidth(URL.createObjectURL(file));
        }
        const { uploadResults, isImage: isFilesImages } = await processAndUploadFileToS3({
          resource: file,
          filename: file.name,
          dimensions: [
            ImageDimensions[ImageDimensionKey.SMALL],
            ImageDimensions[ImageDimensionKey.MEDIUM],
            originalImageDimension,
            ImageDimensions[ImageDimensionKey.LARGE],
          ],
        });
        const mapped = {
          filename: uploadResults[0].fileName,
          originalUrl: uploadResults[0].url,
          smallUrl: uploadResults[0].url,
          mediumUrl: uploadResults[0].url,
          largeUrl: uploadResults[0].url,
          ...(isFilesImages && {
            filename: uploadResults[ImageDimensionKey.DEFAULT].fileName,
            originalUrl: uploadResults[ImageDimensionKey.DEFAULT].url,
            smallUrl: uploadResults[ImageDimensionKey.SMALL].url,
            mediumUrl: uploadResults[ImageDimensionKey.MEDIUM].url,
            largeUrl: uploadResults[ImageDimensionKey.LARGE].url,
          }),
        };
        await postMedia(mapped);
      } catch (err) {
        setErrorS3Upload(err as Error);
        setIsUploading(false);
      }
    },
    [postMedia],
  );
  const reset = () => {
    setErrorS3Upload(null);
    setIsUploading(false);
  };
  return {
    data,
    upload,
    error: errorS3Upload || errorResponse,
    isUploading,
    reset,
  };
}

export default useMediaUpload;
