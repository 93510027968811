import { useContext, useEffect, useState } from 'react';

import { Formik, FormikHelpers as FormikActions } from 'formik';
import { useParams } from 'react-router';
import * as Yup from 'yup';

import { Card, Grid, Typography, styled } from '@mui/material';

import { SubmitButton } from '../../../components/buttons';
import { FormContainer } from '../../../components/forms';
import { TextField } from '../../../components/inputs';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { ErrorMessages } from '../../../constants';
import { Defaults } from '../../../constants';
import { useGetUser } from '../../../lib/user/hooks/useGetUser';
import { useUpdateUser, UserUpdateDTO } from '../../../lib/user/hooks/useUpdateUser';

type AccountFormType = {
  email: string;
  currentPassword: string;
  newPassword: string;
};

const AccountEditSchema = Yup.object().shape({
  email: Yup.string().email(ErrorMessages.INVALID_EMAIL).required(ErrorMessages.REQUIRED_EMAIL),
  currentPassword: Yup.string()
    .min(Defaults.MINIMUM_PASSWORD_LENGTH, ErrorMessages.INVALID_PASSWORD_LENGTH)
    .required('Current Password is required'),
  newPassword: Yup.string()
    .min(Defaults.MINIMUM_PASSWORD_LENGTH, ErrorMessages.INVALID_NEW_PASSWORD_LENGTH)
    .required('New Password is required'),
});

const setInitValue = (value?: string | boolean) => value || ' ';

const AccountEdit = () => {
  const { id } = useParams();
  const userId = id ?? '';
  const { data: user, isLoading: isUserLoading } = useGetUser(userId);
  const {
    data: userUpdated,
    error: updateError,
    isLoading: isUpdateLoading,
    variables: updateVariables,
    mutate: update,
  } = useUpdateUser(userId);
  const [initialValues, setInitialValues] = useState<
    | {
        email: string;
        currentPassword: string;
        newPassword: string;
      }
    | undefined
  >(undefined);
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);

  useEffect(() => {
    if (!userId || !user) {
      return;
    }
    setInitialValues({ email: user.userPrivate.email, currentPassword: '', newPassword: '' });
  }, [userId, user]);

  useEffect(() => {
    if (isUpdateLoading) {
      return;
    }

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!isUpdateLoading) {
      if (updateError) {
        message = (updateError as Error).message;
        severity = 'error';
      } else if (userUpdated) {
        message = `${updateVariables?.username} saved successfully.`;
      }
    }

    if (message === '') {
      return;
    }

    setAlertSnackbar({ message, severity });
  }, [userUpdated, updateError, setAlertSnackbar, isUpdateLoading, updateVariables?.username]);

  const handleSubmit = async (values: AccountFormType, actions: FormikActions<AccountFormType>) => {
    if (!user) {
      return;
    }
    const userObj: UserUpdateDTO = {
      username: user.username,
      ...values,
      firstName: user.firstName,
      lastName: user.lastName,
      country: user.country,
      city: user.city,
      shortBio: user.shortBio,
      created: user.created,
    };
    await update(userObj);
    actions.resetForm();
  };

  return (
    <Container>
      <TopBar>
        <Typography variant="h5">Account</Typography>
      </TopBar>
      <Card>
        {isUserLoading && <Spinner />}
        {!isUserLoading && initialValues && (
          <Formik initialValues={initialValues} validationSchema={AccountEditSchema} validateOnBlur={true} onSubmit={handleSubmit}>
            {({ values, errors, touched, handleChange, handleBlur }) => (
              <FormContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.email && !!errors.email}
                      type="text"
                      name="email"
                      label="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={setInitValue(touched.email && errors.email)}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.currentPassword && !!errors.currentPassword}
                      type="password"
                      name="currentPassword"
                      label="Current Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.currentPassword}
                      helperText={setInitValue(touched.currentPassword && errors.currentPassword)}
                      disabled={isUpdateLoading}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.newPassword && !!errors.newPassword}
                      type="password"
                      name="newPassword"
                      label="New Password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.newPassword}
                      helperText={setInitValue(touched.newPassword && errors.newPassword)}
                      disabled={isUpdateLoading}
                    />
                  </Grid>
                </Grid>
                <BottomContainer>
                  <SubmitButton type="submit" variant="contained" disabled={isUpdateLoading} loading={isUpdateLoading}>
                    Save
                  </SubmitButton>
                </BottomContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </Card>
    </Container>
  );
};

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
});

const BottomContainer = styled('div')({
  marginTop: '20px',
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  alignItems: 'center',
});

export default AccountEdit;
