import { Route, Routes } from 'react-router';

import SuggestedTopicsList from './SuggestedTopicsList';

function TopicModule() {
  return (
    <Routes>
      <Route path="" element={<SuggestedTopicsList />} />
    </Routes>
  );
}

export default TopicModule;
