export class Deferred<T> {
  promise: Promise<T>;
  reject: (reason?: any) => void = () => {
    // do nothing
  };
  resolve: (value: T) => void = () => {
    // do nothing
  };
  constructor() {
    this.promise = new Promise<T>((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}
