import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  Box,
  styled,
  Button,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { CreateButton } from '../../../components/buttons';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { SearchField } from '../../../components/inputs';
import { Pagination } from '../../../components/paginations';
import { Spinner } from '../../../components/spinners';
import { Defaults } from '../../../constants';
import { useDeleteUser, useGetUsersByPage, useGetUsersCount } from '../../../lib/user/hooks';

// TODO handle error
const UserList = () => {
  const navigate = useNavigate();
  const [optionsUpdated, setOptionsUpdated] = useState(true);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    sort: StringParam,
  });
  const { page, search, sort } = query;
  const searchString = search && { search };
  const debouncedSearchFn = useDebouncedCallback((value: string) => {
    setQuery({ search: value, page: 0 });
  }, Defaults.SEARCH_DEBOUNCE_MS);

  const { data, isLoading, error } = useGetUsersByPage({
    page: Number(page) || 0,
    sort: String(sort || ''),
    ...searchString,
  });
  const { data: countData, error: countError, isLoading: isLoadingCount } = useGetUsersCount({ ...searchString });

  useEffect(() => {
    if (!optionsUpdated) {
      return;
    }
    setOptionsUpdated(false);
  }, [optionsUpdated, page, sort, search]);

  useEffect(() => {
    setOptionsUpdated(true);
  }, [setOptionsUpdated, page, sort, search]);

  type SortDirection = 'asc' | 'desc' | undefined;

  const setNewSort = (column: string, direction: SortDirection) => {
    const newSort = direction ? `${column} ${direction}` : undefined;
    setQuery({ sort: newSort });
  };

  const handleAddUser = () => navigate(`/cms/users/new`);
  const handleEditUser = (userId: number) => navigate(`/cms/users/${userId}`);
  const deleteUser = useDeleteUser();
  const handleDelete = async (id: string) => {
    await deleteUser.mutate(id);
  };

  const setNewPage = (page: number) => setQuery({ page: page - 1 });

  const sortToObj = (sortStr: string | undefined) => {
    const initialValues = { column: '', direction: undefined };
    if (typeof sortStr !== 'string') {
      return initialValues;
    }
    const [column, direction] = sortStr.split(' ');
    if (typeof column !== 'string' || !['asc', 'desc', undefined].includes(direction)) {
      return initialValues;
    }
    return { ...initialValues, column, direction: direction as SortDirection };
  };

  const sortObj = sortToObj(sort ?? '');

  const columns = [
    {
      text: 'User ID',
      name: 'id',
    },
    {
      text: 'Email Address',
      name: 'user_private.email',
    },
    {
      text: 'First Name',
      name: 'user.firstName',
    },
    {
      text: 'Last Name',
      name: 'user.lastName',
    },
    {
      text: 'Username',
      name: 'user.username',
    },
    {
      text: 'Country',
      name: 'user.country',
    },
    {
      text: 'City',
      name: 'user.city',
    },
    {
      text: 'Created',
      name: 'user.created',
    },
    {
      text: 'Actions',
      name: 'actions',
    },
  ];

  return (
    <Container>
      <FullWidthBar>
        <Typography variant="h5">Users</Typography>
        <StyledSearchField
          searchString={search ?? ''}
          onSearch={(search: string) => {
            debouncedSearchFn(search);
          }}
        />
        <CreateButton variant="contained" onClick={handleAddUser}>
          Add New User
        </CreateButton>
      </FullWidthBar>

      <TableContainer sx={{ backgroundColor: 'white' }}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <PointerTableRow>
              {columns.map((column) => (
                <TableCell key={column.name}>
                  <TableSortLabel
                    active={sortObj.column === column.name}
                    direction={sortObj.direction}
                    onClick={() => {
                      const isAsc = sortObj.column === column.name && sortObj.direction === 'asc';
                      setNewSort(column.name, isAsc ? 'desc' : 'asc');
                    }}
                    component="a"
                  >
                    {column.text}
                    {sortObj.column === column.name ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortObj.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </PointerTableRow>
          </TableHead>
          <TableBody>
            {(isLoading || isLoadingCount) && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !error &&
              data &&
              data.map((row: any) => (
                <StyledTableRow key={row.id} onClick={() => handleEditUser(row.id)}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.userPrivate.email}</TableCell>
                  <TableCell>{row.firstName}</TableCell>
                  <TableCell>{row.lastName}</TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.country}</TableCell>
                  <TableCell>{row.city}</TableCell>
                  <TableCell>{new Date(row.created).toLocaleDateString()}</TableCell>
                  <TableCell onClick={(event) => event.stopPropagation()}>
                    <ConfirmDialog
                      trigger={(open) => (
                        <Button onClick={open} type="button" style={{ textTransform: 'capitalize' }}>
                          Delete
                        </Button>
                      )}
                      title="Delete User"
                      description="Are you sure you want to delete this user?"
                      onConfirm={async (close) => {
                        await handleDelete(row.id);
                        close();
                      }}
                    ></ConfirmDialog>
                  </TableCell>
                </StyledTableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      {countData !== null && countData !== 0 && !countError && (
        <FullWidthBar>
          <Typography variant="subtitle1">Total: {countData}</Typography>
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(countData / Defaults.LIST_PAGE_SIZE)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        </FullWidthBar>
      )}
    </Container>
  );
};

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
});

const FullWidthBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PointerTableRow = styled(TableRow)({
  cursor: 'pointer',
});

const StyledTableRow = muiStyled(PointerTableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledPagination = styled(Pagination)({
  alignSelf: 'flex-end',
});

const StyledSearchField = styled(SearchField)({
  marginLeft: 'auto',
});

export default UserList;
