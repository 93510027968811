/**
 * Importing react-jwt library
 */
import { decodeToken } from 'react-jwt';
import { useMutation, UseMutationOptions } from 'react-query';

import { User } from '../../../types/User';
import Api, { setAccessToken } from '../../api/api';

export type LogInDTO = {
  email: string;
  password: string;
};

export const login = async (params: LogInDTO) => {
  const [response, bodyOrError] = await Api.post('users/login', params);

  if (!response.ok) {
    throw bodyOrError;
  }

  /**
   * Decoding jwt using react-jwt library
   */
  const decoded = decodeToken(bodyOrError.accessToken) as any;

  setAccessToken(bodyOrError.accessToken, bodyOrError.refreshToken);

  return {
    id: decoded?.sub,
    username: decoded?.name,
    email: '',
    created: '',
    modified: '',
    roles: decoded?.roles
  };
};

export const useLogin = (options?: Omit<UseMutationOptions<User, Error, LogInDTO, unknown>, 'mutationFn' | 'mutationKey'>) =>
  useMutation<User, Error, LogInDTO>('login', login, options);
