import { Link, useLocation } from 'react-router-dom';

import { List, ListItem, ListItemButton, ListItemText, Drawer, DrawerProps, Toolbar, Typography } from '@mui/material';

type NavigationDrawerProps = DrawerProps & {
  sections: any[];
};

const NavigationDrawer = (props: NavigationDrawerProps) => {
  const location = useLocation();
  const { sections, ...others } = props;
  const { ...rest } = others; // Removed staticContext to fix warning

  return (
    <Drawer {...rest}>
      <Toolbar>
        <Typography variant="h6" component="div" color="primary">
          CMS
        </Typography>
      </Toolbar>
      <List>
        {sections.map((section: any) => (
          <ListItem disablePadding component={Link} to={section.path} key={section.path} selected={location.pathname === section.path}>
            <ListItemButton>
              <ListItemText primary={section.name} sx={{ color: 'black' }} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default NavigationDrawer;
