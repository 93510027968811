import { Form } from 'formik';

import { styled } from '@mui/material';


export const FormContainer = styled(Form)({
  width: '100%',
  paddingTop: '30px',
  paddingInline: '20px',
  paddingBottom: '20px',

  '@media (min-width: 1024px)': {
    paddingTop: '50px',
    paddingInline: '40px',
    paddingBottom: '40px',
  },
});
