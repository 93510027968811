import { useMutation } from 'react-query';

import Api from '../../api/api';
import { CategoryUpdateDTO } from '../../category/hooks/useUpdateCategory';

export type TopicDto = {
  id?: string;
  title: string;
  category: CategoryUpdateDTO;
  published: boolean;
};

export const createTopic = async (params: TopicDto[]) => {
  const [response, bodyOrError] = await Api.post('topics', params);

  if (!response.ok) {
    throw bodyOrError;
  }
  return bodyOrError;
};

export const useCreateTopic = () => useMutation('createTopic', createTopic);
