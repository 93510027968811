import React from 'react';

import { Box, styled, Typography } from '@mui/material';

import { TextField } from '.';

export interface BrowseUploadProps {
  fileLabel: string;
  name: string;
  disabled?: boolean;
  handleFileInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (e: React.FocusEvent<any>) => void;
  errorMsg?: string;
  helperText?: any;
  error?: boolean;
}

const BrowseUpload = ({ fileLabel, name, handleFileInput, handleBlur, disabled, errorMsg, helperText, error }: BrowseUploadProps) => {
  return (
    <StyledBox>
      <StyledTypography>{fileLabel}</StyledTypography>
      <TextField
        type="file"
        name={name}
        onChange={handleFileInput}
        error={error}
        onBlur={handleBlur}
        disabled={disabled}
        helperText={helperText}
      />
      {errorMsg ? <StyledTypographyErrorMsg>{errorMsg}</StyledTypographyErrorMsg> : null}
    </StyledBox>
  );
};

const StyledBox = styled(Box)({
  marginBottom: '30px',
  paddingBottom: '10px',
});

const StyledTypography = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.75rem',
  letterSpacing: '0.01071em',
  color: 'rgba(0, 0, 0, 0.87)',
});

const StyledTypographyErrorMsg = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.75rem',
  letterSpacing: '0.01071em',
  color: '#F48F93',
});

export { BrowseUpload };
