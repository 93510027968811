import { MediaType, MediaUploadOptions } from '../lib/media/media.util';

export type Media = {
  id: string;
  filename: string;
  originalUrl: string;
  smallUrl: string;
  mediumUrl: string;
  largeUrl: string;
  created: string;
  modified: string;
};

export type MediaDimension = {
  height: number;
  width: number;
};

export enum AWS_ACL {
  /** Most secure: Works for presigned URLs */
  PRIVATE = 'private',
  /** Requires Public Access enabled in bucket */
  PUBLIC_READ = 'public-read',
  /** Requires Public Access enabled in bucket */
  PUBLIC_READ_WRITE = 'public-read-write',
  AUTHENTICATED_READ = 'authenticated-read',
  AWS_EXEC_READ = 'aws-exec-read',
  BUCKET_OWNER_READ = 'bucket-owner-read',
  BUCKET_OWNER_FULL_CONTROL = 'bucket-owner-full-control',
}
/** @deprecated by AWS soon in favor of Bucket Policies */
export type AWSACL = AWS_ACL;

export type MediaS3UploadOptions = MediaUploadOptions & {
  type: MediaType;
  acl: AWSACL;
};

export type ResizeImageOptions = {
  dimensions: MediaDimension[];
  compressFormat?: 'JPEG' | 'PNG';
};

export type MediaSignature = {
  url: string;
  fields: MediaSignatureFields;
};

export type MediaSignatureFields = {
  acl: string;
  success_action_status: string;
  'Content-Type': string;
  bucket: string;
  'X-Amz-Algorithm': string;
  'X-Amz-Credential': string;
  'X-Amz-Date': string;
  key: string;
  Policy: string;
  'X-Amz-Signature': string;
};
