import { useEffect, useContext, useState } from 'react';

import { Formik, FormikHelpers } from 'formik';
import { useNavigate, useParams } from 'react-router';
import * as Yup from 'yup';

import { Typography, Card, Button, Grid, styled } from '@mui/material';

import { SubmitButton } from '../../../components/buttons';
import { ConfirmDialog } from '../../../components/dialogs';
import { FormContainer } from '../../../components/forms';
import { TextField, SelectField } from '../../../components/inputs';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { Defaults, ErrorMessages, REGEX_WHITESPACE } from '../../../constants';
import {
  useGetUser,
  useCreateUser,
  useUpdateUser,
  useDeleteUser,
  useGetAllRoles,
  RolesDTO,
  UserCreateDTO,
  UserUpdateDTO,
  ProfileMediaDTO,
} from '../../../lib/user/hooks';

type UserFormType = {
  id: number | string;
  username: string;
  email: string;
  firstName: string;
  lastName: string;
  country: string;
  city: string;
  shortBio: string;
  created: Date;
  password?: string;
  profileMedia?: ProfileMediaDTO;
  roles?: RolesDTO[];
};

const UserEditSchema = Yup.object().shape({
  email: Yup.string().email(ErrorMessages.INVALID_EMAIL).required(ErrorMessages.REQUIRED_EMAIL),
  username: Yup.string().required(ErrorMessages.REQUIRED_USERNAME),
  firstName: Yup.string().required(ErrorMessages.REQUIRED_FIRSTNAME),
  lastName: Yup.string().required(ErrorMessages.REQUIRED_LASTNAME),
  country: Yup.string().required(ErrorMessages.REQUIRED_COUNTRY),
  city: Yup.string().required(ErrorMessages.REQUIRED_CITY),
  shortBio: Yup.string().required(ErrorMessages.REQUIRED_SHORT_BIO),
});

const UserCreateSchema = Yup.object().shape({
  email: Yup.string().email(ErrorMessages.INVALID_EMAIL).required(ErrorMessages.REQUIRED_EMAIL),
  username: Yup.string().required(ErrorMessages.REQUIRED_USERNAME),
  password: Yup.string()
    .min(Defaults.MINIMUM_PASSWORD_LENGTH, ErrorMessages.INVALID_PASSWORD_LENGTH)
    .matches(REGEX_WHITESPACE, ErrorMessages.NO_WHITESPACE_PASSWORD)
    .required(ErrorMessages.REQUIRED_PASSWORD),
  roles: Yup.array().nullable().min(Defaults.MINIMUM_ROLES_LENGTH, ErrorMessages.INVALID_ROLE_LENGTH).required(ErrorMessages.REQUIRED_ROLE),
});

function UserEdit() {
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = id ?? '';
  const getUser = useGetUser(userId);
  const updateUser = useUpdateUser(userId);
  const createUser = useCreateUser();
  const deleteUser = useDeleteUser();
  const { data } = useGetAllRoles();
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);
  const [isSubmitting, setSubmitting] = useState(false);

  useEffect(() => {
    setSubmitting(createUser.isLoading || updateUser.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!updateUser.isLoading) {
      if (updateUser.error) {
        message = (updateUser.error as Error).message;
      } else if (updateUser.data) {
        message = `${updateUser?.variables?.username} saved successfully.`;
      }
    }

    if (!createUser.isLoading) {
      if (createUser.error) {
        message = (createUser.error as Error).message;
      } else if (createUser.data) {
        message = `${createUser.data.username} created successfully.`;
      }
    }

    if (!deleteUser.isLoading) {
      if (deleteUser.error) {
        message = deleteUser.error.message;
      } else if (deleteUser.data) {
        message = `${deleteUser.data.username} deleted successfully.`;
      }
    }

    if (updateUser.error || createUser.error || deleteUser.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/users');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [
    navigate,
    updateUser.variables,
    updateUser.data,
    updateUser.error,
    updateUser.isLoading,
    createUser.data,
    createUser.error,
    createUser.isLoading,
    deleteUser.data,
    deleteUser.error,
    deleteUser.isLoading,
    setAlertSnackbar,
  ]);

  const handleSubmit = async (values: UserFormType, actions: FormikHelpers<UserFormType>) => {
    if (userId === 'new') {
      let newUser: UserCreateDTO = {
        username: values.username,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        country: values.country,
        city: values.city,
        shortBio: values.shortBio,
        password: values.password!,
        roles: values.roles as RolesDTO[],
      };
      await createUser.mutate(newUser);
    } else {
      const userObj: UserUpdateDTO = {
        username: values.username,
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        country: values.country,
        city: values.city,
        shortBio: values.shortBio,
        roles: values.roles as RolesDTO[],
      };
      await updateUser.mutate(userObj);
    }
  };

  const handleDelete = async () => {
    await deleteUser.mutate(userId);
    navigate(-1);
  };

  let initialValues;
  let ready = false;

  if (userId === 'new') {
    initialValues = { id: 'new', username: '', email: '', password: '', roles: [] };
    ready = true;
  } else {
    if (getUser.data) {
      initialValues = { email: getUser.data.userPrivate.email, ...getUser.data };
    }
    ready = !getUser.isLoading;
  }

  const setInitValue = (value?: string | boolean) => value || ' ';

  return (
    <Container>
      <TopBar>
        <Typography variant="h5">User</Typography>
      </TopBar>

      <StyledCard>
        {!ready && <Spinner />}
        {ready && initialValues && (
          <Formik
            initialValues={initialValues}
            validationSchema={userId !== 'new' ? UserEditSchema : UserCreateSchema}
            validateOnBlur={true}
            onSubmit={handleSubmit}
          >
            {({ values, errors, touched, handleChange, handleBlur, setFieldValue }) => (
              <FormContainer>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField name="id" label="ID" value={values.id} disabled helperText=" " />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.username && !!errors.username}
                      type="text"
                      name="username"
                      label="Username"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.username}
                      helperText={setInitValue(touched.username && errors.username)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.email && !!errors.email}
                      type="email"
                      name="email"
                      label="Email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      helperText={setInitValue(touched.email && errors.email)}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <TextField
                      error={touched.firstName && !!errors.firstName}
                      type="text"
                      name="firstName"
                      label="First Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      helperText={setInitValue(touched.firstName && errors.firstName)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.lastName && !!errors.lastName}
                      type="text"
                      name="lastName"
                      label="Last Name"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      helperText={setInitValue(touched.lastName && errors.lastName)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.country && !!errors.country}
                      type="text"
                      name="country"
                      label="Country"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.country}
                      helperText={setInitValue(touched.country && errors.country)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.city && !!errors.city}
                      type="text"
                      name="city"
                      label="City"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.city}
                      helperText={setInitValue(touched.city && errors.city)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={touched.shortBio && !!errors.shortBio}
                      type="text"
                      multiline
                      rows={2}
                      name="shortBio"
                      label="Bio"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.shortBio}
                      helperText={setInitValue(touched.shortBio && errors.shortBio)}
                      disabled={isSubmitting}
                    />
                  </Grid>
                  {userId !== 'new' && (
                    <Grid item xs={12}>
                      <TextField
                        name="created"
                        label="Signed Up Date"
                        value={new Date(values.created).toLocaleDateString()}
                        disabled
                        helperText=" "
                      />
                      {values.profileMedia && (
                        <>
                          <StyledTypography>Profile Photo</StyledTypography>
                          <img src={values.profileMedia?.smallUrl} alt={values.profileMedia?.filename} />
                        </>
                      )}
                    </Grid>
                  )}

                  {userId === 'new' && (
                    <Grid item xs={12}>
                      <TextField
                        error={touched.password && !!errors.password}
                        type="password"
                        name="password"
                        label="Password"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        value={values.password}
                        helperText={setInitValue(touched.password && errors.password)}
                        disabled={isSubmitting}
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <SelectContainer>
                      <SelectField
                        isMulti
                        label="Select Roles"
                        defaultValue={values.roles}
                        name="user-roles"
                        options={data}
                        onChange={(e: RolesDTO) => setFieldValue('roles', e)}
                        placeholder="Select roles"
                        changeOptionLabel="name"
                        changeOptionValue="id"
                        disabled={isSubmitting}
                        required={!(touched.roles && !!errors.roles)}
                      />
                      {touched.roles && !!errors.roles && <SelectErrorMessage>{errors.roles}</SelectErrorMessage>}
                    </SelectContainer>
                  </Grid>
                </Grid>
                <ButtonContainer>
                  <Buttons>
                    <SubmitButton type="submit" disabled={isSubmitting} loading={isSubmitting} variant="contained">
                      Save
                    </SubmitButton>
                    <Button onClick={() => navigate(-1)} type="button">
                      Cancel
                    </Button>
                  </Buttons>
                  {userId !== 'new' && (
                    <ConfirmDialog
                      trigger={(open) => (
                        <Button onClick={open} type="button" color="error">
                          Delete
                        </Button>
                      )}
                      title="Delete User"
                      description="Are you sure you want to delete this user?"
                      onConfirm={async (close) => {
                        await handleDelete();
                        close();
                      }}
                    ></ConfirmDialog>
                  )}
                </ButtonContainer>
              </FormContainer>
            )}
          </Formik>
        )}
      </StyledCard>
    </Container>
  );
}

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
});

const TopBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
});

const StyledCard = styled(Card)({
  display: 'flex',
});

const StyledTypography = styled(Typography)({
  fontSize: '0.875rem',
  lineHeight: '1.75rem',
  letterSpacing: '0.01071em',
  color: 'rgba(0, 0, 0, 0.87)',
});

const ButtonContainer = styled('div')({
  marginTop: '20px',
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',

  '& > *': {
    margin: '5px',
  },
});

const SelectContainer = styled('div')({
  marginTop: '10px',
  marginBottom: '20px',
});

const SelectErrorMessage = styled('p')({
  marginTop: '3px',
  marginLeft: '14px',
  marginBottom: '0px',
  marginRight: '14px',
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
  fontWeight: '400',
  fontSize: '0.75rem',
  color: '#d32f2f',
  lineHeight: '1.66',
});

export default UserEdit;
