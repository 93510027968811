import { Route, Routes } from 'react-router';

import ReportedPostEdit from './ReportedPostEdit';
import ReportedPostsList from './ReportedPostsList';

function PostModule() {
  return (
    <Routes>
      <Route path="" element={<ReportedPostsList />} />
      <Route path=":id" element={<ReportedPostEdit />} />
    </Routes>
  );
}

export default PostModule;
