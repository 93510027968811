import { createContext, Dispatch, SetStateAction, useState, useContext, useEffect } from 'react';

import { Snackbar as MuiSnackbar, Alert, AlertColor } from '@mui/material';

type AlertSnackbarState = {
  severity?: AlertColor;
  message: string;
  duration?: number;
  vertical?: 'bottom' | 'top';
  horizontal?: 'center' | 'left' | 'right';
};

type AlertSnackbarProviderProps = {
  children: JSX.Element;
};

const initialValues: AlertSnackbarState = {
  duration: 0,
  message: '',
  vertical: 'bottom',
  horizontal: 'center',
};

export const AlertSnackbarContext = createContext<[AlertSnackbarState, Dispatch<SetStateAction<AlertSnackbarState>>]>([
  initialValues,
  () => {},
]);

export const AlertSnackbarProvider = ({ children }: AlertSnackbarProviderProps) => {
  const state = useState<AlertSnackbarState>(initialValues);

  return <AlertSnackbarContext.Provider value={state}>{children}</AlertSnackbarContext.Provider>;
};

export const AlertSnackbar = () => {
  const [state] = useContext(AlertSnackbarContext);
  const { duration = 6000, severity, message = '', vertical = 'bottom', horizontal = 'center' } = state;

  const [open, setOpen] = useState(false);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!state) {
      return;
    }
    setOpen(true);
  }, [state]);

  return (
    <>
      <MuiSnackbar open={open} autoHideDuration={duration} anchorOrigin={{ vertical, horizontal }} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </MuiSnackbar>
    </>
  );
};
