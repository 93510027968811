import { Fragment } from 'react';

import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';

import { ThemeProvider, GlobalStyles } from '@mui/material';

import ProtectedRoute from './components/ProtectedRoute';
import { AlertSnackbarProvider, AlertSnackbar } from './components/snackbars';
import { GlobalThemeStyle } from './constants';
import { theme } from './constants/';
import CmsContainer from './features/CmsContainer';
import Login from './features/login/Login';
import LoginCheck from './features/LoginCheck';
import NotFound from './features/NotFound';
import MediaProvider from './lib/media/media.context';
import AdminGuard from './lib/user/admin.guard';
import UserProvider from './lib/user/user.context';

const queryClient = new QueryClient();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalStyles styles={GlobalThemeStyle} />
        <BrowserRouter>
          <QueryClientProvider client={queryClient}>
            <QueryParamProvider adapter={ReactRouter6Adapter}>
              <MediaProvider>
                <UserProvider>
                  <AlertSnackbarProvider>
                    <div className="App">
                      <Routes>
                        <Route path="/" element={<LoginCheck />} />
                        <Route path="/login" element={<Login />} />
                        {/* Protected routes */}
                        <Route element={<ProtectedRoute guards={[AdminGuard]} />}>
                          <Route path="/cms/*" element={<CmsContainer />} />
                        </Route>
                        <Route Component={NotFound} />
                      </Routes>
                      <AlertSnackbar />
                    </div>
                  </AlertSnackbarProvider>
                </UserProvider>
              </MediaProvider>
            </QueryParamProvider>
          </QueryClientProvider>
        </BrowserRouter>
      </Fragment>
    </ThemeProvider>
  );
}

export default App;
