import { createTheme } from '@mui/material';
const baseColors = {
  atomic: '#3F4D55',
  blueWhale: '#002F49',
  black: '#000000',
  emerald: '#3AD47C',
  ghostWhite: '#F1F1F3',
  gainsboro: '#E1E1E1',
  grey: '#7f7f7f',
  matterhorn: '#565656',
  pelorous: '#2388C1',
  red: '#FF0000',
  white: '#FFFFFF',
  destructiveColor: '#FF0000',
};

const appBaseColors = {
  appBar: baseColors.blueWhale,
  primary: baseColors.pelorous,
  secondary: baseColors.emerald,
};

const componentStateColors = {
  activeNavLink: baseColors.blueWhale,
  signInButtonBackground: baseColors.emerald,
  signInFormBackground: baseColors.blueWhale,
  inputBackground: baseColors.gainsboro,
  inputLabel: baseColors.pelorous,
  inputValid: baseColors.emerald,
  inputInvalid: baseColors.red,
};

export const Color = {
  ...baseColors,
  ...appBaseColors,
  ...componentStateColors,
};

/**
 * The floating label color bug as of today:
 * https://bitbucket.org/isbx/boilerplates-cms/pull-requests/2/feature-bplt-66-cms-set-up-theming/diff#comment-102555670
 * https://github.com/material-components/material-components-web/issues/2718
 */

export const GlobalThemeStyle = {
  html: {
    boxSizing: 'border-box',
  },

  body: {
    margin: 0,
  },

  'html, body': {
    height: '100%',
  },

  '*, *:before, *:after': {
    boxSizing: 'border-box',
  },

  '.deleted-row > td': {
    color: 'red',
  },
};

const generatePalette = (colors: { [key: string]: string }) => {
  return Object.keys(colors).reduce((curr, key) => {
    return {
      ...curr,
      [key]: {
        main: colors[key],
      },
    };
  }, {});
};

export type Theme = Readonly<typeof theme>;

export const theme = createTheme({
  palette: generatePalette({
    primary: Color.primary,
    secondary: Color.secondary,
  }),
});
