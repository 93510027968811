import { useMutation, useQueryClient } from 'react-query';

import { User } from '../../../types/User';
import Api from '../../api/api';

export const deleteUser = async (userId: string) => {
  const [response, bodyOrError] = await Api.delete(`users/${userId}`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useDeleteUser = () => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, string>('deleteUser', deleteUser, {
    onSuccess: () => {
      queryClient.invalidateQueries('getUsersByPage');
      queryClient.invalidateQueries('getUsersCount');
    },
  });
};
