import { QueryKey, UseQueryOptions, useQuery } from 'react-query';

import { User } from '../../../types/User';
import Api, { removeAccessToken } from '../../api/api';

const getCurrentUser = async () => {
  const [response, bodyOrError] = await Api.get('users/me');

  if (!response.ok) {
    if (response.status === 403) {
      removeAccessToken();
    }
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetCurrentUser = (options?: Omit<UseQueryOptions<User, Error>, 'mutationFn' | 'mutationKey'>) =>
  useQuery('getCurrentUser' as QueryKey, getCurrentUser, {
    ...options,
    retry: (failureCount, error) => {
      if ('statusCode' in error && (error as any).statusCode === 403) {
        return false;
      }
      return failureCount < 3;
    }
  });
