import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getCategoriesByPage = async (page: number, sort: string, search?: string) => {
  const [response, bodyOrError] = await Api.get(`categories`, {
    page,
    sort,
    search,
  });

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError.data;
};

export const useGetCategoriesByPage = ({ page, sort, search = '' }: { page: number; sort: string; search?: string }) =>
  useQuery(['getCategoriesByPage', page, sort, search], () => getCategoriesByPage(page, sort, search), {
    initialData: [],
  });
