import { useMutation, UseMutationOptions } from 'react-query';

import Api, { removeAccessToken } from '../../api/api';

export const logout = async () => {
  await Api.post('users/logout');
  removeAccessToken();
};

export const useLogout = (options?: Omit<UseMutationOptions, 'mutationFn' | 'mutationKey'>) => {
  return useMutation('logout', logout, options);
};
