import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getCategoriesCount = async (search: string) => {
  const [response, bodyOrError] = await Api.get(`categories/count`, { search });

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetCategoriesCount = ({ search = '' }: { search?: string }) =>
  useQuery(['getCategoriesCount', search], () => getCategoriesCount(search));
