import { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  Box,
  styled,
  Button,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { SearchField } from '../../../components/inputs';
import { Pagination } from '../../../components/paginations';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { Defaults } from '../../../constants';
import { useApproveTopic, useDispproveTopic } from '../../../lib/topic/hooks';
import useGetSuggestCategoryTopics from '../../../lib/topic/hooks/useGetSuggestedCategoryTopics';

const SuggestedTopicsList = () => {
  const navigate = useNavigate();
  const [optionsUpdated, setOptionsUpdated] = useState(true);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    sort: StringParam,
  });
  const { page, search, sort } = query;
  const searchString = search && { search };
  const debouncedSearchFn = useDebouncedCallback((value: string) => {
    setQuery({ search: value, page: 0 });
  }, Defaults.SEARCH_DEBOUNCE_MS);

  const {
    data: suggestedTopic,
    isLoading,
    error,
  } = useGetSuggestCategoryTopics({
    page: Number(page) || 0,
    sort: String(sort || ''),
    ...searchString,
    published: false,
  });
  const approveSuggestedTopic = useApproveTopic();
  const disapproveSuggestedTopic = useDispproveTopic();
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);
  const [, setSubmitting] = useState(false);

  useEffect(() => {
    setSubmitting(approveSuggestedTopic.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!approveSuggestedTopic.isLoading) {
      if (approveSuggestedTopic.error) {
        message = (approveSuggestedTopic.error as Error).message;
      } else if (approveSuggestedTopic.data) {
        message = `${approveSuggestedTopic?.data.title} suggested topic has been approved.`;
      }
    }

    if (approveSuggestedTopic.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/suggested-topics');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [approveSuggestedTopic.data, approveSuggestedTopic.error, approveSuggestedTopic.isLoading, navigate, setAlertSnackbar]);

  useEffect(() => {
    setSubmitting(disapproveSuggestedTopic.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!disapproveSuggestedTopic.isLoading) {
      if (disapproveSuggestedTopic.error) {
        message = (disapproveSuggestedTopic.error as Error).message;
      } else if (disapproveSuggestedTopic.data) {
        message = `${disapproveSuggestedTopic?.data.title} suggested topic has been disapproved.`;
      }
    }

    if (disapproveSuggestedTopic.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/suggested-topics');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [disapproveSuggestedTopic.data, disapproveSuggestedTopic.error, disapproveSuggestedTopic.isLoading, navigate, setAlertSnackbar]);

  useEffect(() => {
    if (!optionsUpdated) {
      return;
    }
    setOptionsUpdated(false);
  }, [optionsUpdated, page, sort, search]);

  useEffect(() => {
    setOptionsUpdated(true);
  }, [setOptionsUpdated, page, sort, search]);

  type SortDirection = 'asc' | 'desc' | undefined;

  const setNewSort = (column: string, direction: SortDirection) => {
    const newSort = direction ? `${column} ${direction}` : undefined;
    setQuery({ sort: newSort });
  };

  const setNewPage = (page: number) => setQuery({ page: page - 1 });

  const sortToObj = (sortStr: string | undefined) => {
    const initialValues = { column: '', direction: undefined };
    if (typeof sortStr !== 'string') {
      return initialValues;
    }
    const [column, direction] = sortStr.split(' ');
    if (typeof column !== 'string' || !['asc', 'desc', undefined].includes(direction)) {
      return initialValues;
    }
    return { ...initialValues, column, direction: direction as SortDirection };
  };

  const sortObj = sortToObj(sort ?? '');

  const columns = [
    {
      text: 'Name',
      name: 'topic.title',
    },
    {
      text: 'Category',
      name: 'category.title',
    },
    {
      text: 'Username Requested',
      name: 'user.username',
    },
    {
      text: 'Date Requested',
      name: 'topic.created',
    },
    {
      text: 'Actions',
      name: '',
    },
  ];

  return (
    <Container>
      <FullWidthBar>
        <Typography variant="h5" sx={{ marginRight: '20px' }}>
          Suggested Topics
        </Typography>
        <StyledSearchField
          searchString={search ?? ''}
          onSearch={(search: string) => {
            debouncedSearchFn(search);
          }}
        />
      </FullWidthBar>

      <TableContainer sx={{ backgroundColor: 'white' }}>
        <Table aria-labelledby="tableTitle">
          <TableHead>
            <PointerTableRow>
              {columns.map((column) => (
                <TableCell key={column.name}>
                  <TableSortLabel
                    active={sortObj.column === column.name}
                    direction={sortObj.direction}
                    onClick={() => {
                      const isAsc = sortObj.column === column.name && sortObj.direction === 'asc';
                      setNewSort(column.name, isAsc ? 'desc' : 'asc');
                    }}
                    component="a"
                  >
                    {column.text}
                    {sortObj.column === column.name ? (
                      <Box component="span" sx={visuallyHidden}>
                        {sortObj.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCell>
              ))}
            </PointerTableRow>
          </TableHead>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={3}>
                  <Spinner />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              !error &&
              suggestedTopic.data &&
              suggestedTopic.data.map((row: any, index: number) => {
                return (
                  <StyledTableRow key={index} className={row.deleted ? 'deleted-row' : 'row'}>
                    <TableCell>{row.title}</TableCell>
                    <TableCell>{row.category.title}</TableCell>
                    <TableCell>{row.user?.username}</TableCell>
                    <TableCell>{new Date(row.created).toLocaleDateString()}</TableCell>
                    <TableCell>
                      <Button
                        onClick={() => approveSuggestedTopic.mutate({ topicId: row.id })}
                        type="button"
                        style={{ textTransform: 'capitalize' }}
                      >
                        Approve
                      </Button>
                      <Button
                        onClick={() => disapproveSuggestedTopic.mutate({ topicId: row.id })}
                        type="button"
                        style={{ textTransform: 'capitalize' }}
                      >
                        Disapprove
                      </Button>
                    </TableCell>
                  </StyledTableRow>
                );
              })}
            {suggestedTopic.data.length === 0 && (
              <StyledTableRow>
                <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                  No suggested topics records found.
                </TableCell>
              </StyledTableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {suggestedTopic.total !== null && suggestedTopic.total !== 0 && (
        <FullWidthBar>
          <Typography variant="subtitle1">Total: {suggestedTopic.total}</Typography>
          <StyledPagination
            current={Number(page) + 1 || 1}
            total={Math.ceil(suggestedTopic.total / Defaults.LIST_PAGE_SIZE)}
            onPageChange={(page: number) => setNewPage(page)}
          />
        </FullWidthBar>
      )}
    </Container>
  );
};

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
});

const FullWidthBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PointerTableRow = styled(TableRow)({
  cursor: 'pointer',
});

const StyledTableRow = muiStyled(PointerTableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledPagination = styled(Pagination)({
  alignSelf: 'flex-end',
});

const StyledSearchField = styled(SearchField)({
  marginLeft: 'auto',
});

export default SuggestedTopicsList;
