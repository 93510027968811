import { CircularProgress, styled } from '@mui/material';

const SpinnerContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100px',
  width: '100%',
});

export const Spinner = () => {
  return (
    <SpinnerContainer>
      <CircularProgress color="primary" />
    </SpinnerContainer>
  );
};
