import React, { useState } from 'react';

import { Spinner } from '../../components/spinners';
import { User } from '../../types/User';
import { useGetCurrentUser } from '../user/hooks';

export type UserContextType = User | null | undefined;
export type UserSetStateActionDispatch = React.Dispatch<React.SetStateAction<UserContextType>>;
type UserContextState = {
  user: UserContextType;
  setUser: UserSetStateActionDispatch;
};
const UserContext = React.createContext<UserContextState>({
  user: undefined,
  setUser: () => {}
});

type UserProviderProps = {
  children: JSX.Element;
};

function UserProvider(props: UserProviderProps) {
  const { children } = props;
  const [user, setUser] = useState<UserContextType>(undefined);

  const { isLoading } = useGetCurrentUser({
    onSuccess: setUser,
    onError: () => setUser(null)
  });

  if (isLoading) {
    // Do not load the children of the provider if the user is undefined.
    // Lets further wait...
    // undefined = we have not determined the login state yet
    // null = not logged in
    // User value = logged in
    return <Spinner />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        setUser
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export { UserContext };
export default UserProvider;
