import { useState } from 'react';

import { useFormik } from 'formik';

import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, styled } from '@mui/material';

import { SubmitButton } from '../buttons';

const StyledDialogActions = styled(DialogActions)({
  padding: '1rem',
});

type ConfirmDialogProps = {
  title: string;
  description: string;
  trigger: (handleOpen: () => void) => JSX.Element;
  onConfirm: (handleClose: () => void, ...args: any) => {};
  cancelBtnText?: string;
  submitBtnText?: string;
  disabled?: boolean;
  loading?: boolean;
};

export const ConfirmDialog = ({
  title,
  description,
  trigger,
  onConfirm,
  cancelBtnText = 'Disagree',
  submitBtnText = 'Agree',
  disabled,
  loading,
}: ConfirmDialogProps) => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const formik = useFormik({
    initialValues: {},
    onSubmit: (...args) => {
      onConfirm(handleClose, ...args);
    },
  });

  return (
    <>
      {trigger(handleOpen)}
      <Dialog open={open} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
          </DialogContent>
          <StyledDialogActions>
            <Button disabled={disabled} onClick={handleClose} color="secondary">
              {cancelBtnText}
            </Button>
            <SubmitButton loading={loading} disabled={disabled} autoFocus variant="contained" type="submit">
              {submitBtnText}
            </SubmitButton>
          </StyledDialogActions>
        </form>
      </Dialog>
    </>
  );
};
