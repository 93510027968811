import { useContext, useEffect, useState } from 'react';

import { Formik, FormikHelpers } from 'formik';
import { useNavigate } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';
import { NumberParam, StringParam, useQueryParams } from 'use-query-params';

import {
  Typography,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableSortLabel,
  Box,
  styled,
  Button,
  Grid,
} from '@mui/material';
import { styled as muiStyled } from '@mui/material';
import { visuallyHidden } from '@mui/utils';

import { CreateButton, SubmitButton } from '../../../components/buttons';
import { ConfirmDialog } from '../../../components/dialogs/ConfirmDialog';
import { FormContainer } from '../../../components/forms/FormContainer';
import { SearchField, SelectField } from '../../../components/inputs';
import { Modal } from '../../../components/Modal';
import { AlertSnackbarContext } from '../../../components/snackbars';
import { Spinner } from '../../../components/spinners';
import { Defaults } from '../../../constants';
import { CategoryUpdateDTO, useGetCategoriesByFeatured, useUpdateCategory } from '../../../lib/category/hooks';

type FeaturedCategoryFormType = {
  unfeaturedCategories?: CategoryUpdateDTO;
};

const FeaturedCategoryList = () => {
  const navigate = useNavigate();
  const updateCategory = useUpdateCategory();
  const [optionsUpdated, setOptionsUpdated] = useState(true);
  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    search: StringParam,
    sort: StringParam,
  });
  const { page, search, sort } = query;
  const searchString = search && { search };
  const debouncedSearchFn = useDebouncedCallback((value: string) => {
    setQuery({ search: value, page: 0 });
  }, Defaults.SEARCH_DEBOUNCE_MS);

  const { data } = useGetCategoriesByFeatured({
    page: Number(page) || 0,
    sort: String(sort || ''),
    ...searchString,
    featured: false,
  });

  const {
    data: featuredCategories,
    isLoading: isLoadingCategoryFeaured,
    error: errorCategoryFeatured,
  } = useGetCategoriesByFeatured({
    page: Number(page) || 0,
    sort: String(sort || ''),
    ...searchString,
    featured: true,
  });
  const [, setAlertSnackbar] = useContext(AlertSnackbarContext);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isSubmitting, setSubmitting] = useState(false);
  const [isFeatured, setIsFeatured] = useState(false);
  const [unFeaturedCategories, setUnfeaturedCategories] = useState<CategoryUpdateDTO[]>([]);

  useEffect(() => {
    if (!optionsUpdated) {
      return;
    }
    setOptionsUpdated(false);
  }, [optionsUpdated, page, sort, search]);

  useEffect(() => {
    setOptionsUpdated(true);
  }, [setOptionsUpdated, page, sort, search]);

  useEffect(() => {
    const filteredCategories = data.filter((item: any) => item.featured === null && item.deleted === null);
    setUnfeaturedCategories(filteredCategories);
  }, [data]);

  useEffect(() => {
    setSubmitting(updateCategory.isLoading);

    let message = '';
    let severity: 'error' | 'success' | 'info' | 'warning' | undefined = 'success';

    if (!updateCategory.isLoading) {
      if (updateCategory.error) {
        message = (updateCategory.error as Error).message;
      } else if (updateCategory.data) {
        if (isFeatured) {
          message = `${updateCategory?.data?.title} successfully set as featured category.`;
        } else {
          message = `${updateCategory?.data?.title} successfully removed as featured category.`;
        }
      }
    }

    if (updateCategory.error) {
      severity = 'error';
    }

    if (message !== '') {
      if (severity === 'success') {
        navigate('/cms/categories/featured');
      }
      setAlertSnackbar({ message, severity });
    }
  }, [
    navigate,
    isFeatured,
    updateCategory?.variables,
    updateCategory.data,
    updateCategory.error,
    updateCategory.isLoading,
    setAlertSnackbar,
  ]);

  type SortDirection = 'asc' | 'desc' | undefined;

  const setNewSort = (column: string, direction: SortDirection) => {
    const newSort = direction ? `${column} ${direction}` : undefined;
    setQuery({ sort: newSort });
  };

  const sortToObj = (sortStr: string | undefined) => {
    const initialValues = { column: '', direction: undefined };
    if (typeof sortStr !== 'string') {
      return initialValues;
    }
    const [column, direction] = sortStr.split(' ');
    if (typeof column !== 'string' || !['asc', 'desc', undefined].includes(direction)) {
      return initialValues;
    }
    return { ...initialValues, column, direction: direction as SortDirection };
  };

  const sortObj = sortToObj(sort ?? '');

  const columns = [
    {
      text: 'Name',
      name: 'category.title',
    },
    {
      text: '# of topics',
      name: 'category_topic_count',
    },
    {
      text: '# of comments',
      name: 'category_post_comment_count',
    },
    {
      text: '# of views',
      name: 'category_post_view_count',
    },
    {
      text: 'Actions',
      name: '',
    },
  ];

  const handleSubmit = async (values: FeaturedCategoryFormType, actions: FormikHelpers<FeaturedCategoryFormType>) => {
    const isPublished = values.unfeaturedCategories?.published === null ? false : true;

    updateCategory.mutate({
      categoryId: values.unfeaturedCategories?.id || '',
      params: { published: isPublished, featured: true },
    });

    setIsFeatured(true);
    setSubmitting(true);

    // close modal
    setModalIsOpen(false);
  };

  const handleRemoveFeaturedCategory = async (item: any) => {
    const isPublished = item.published === null ? false : true;

    updateCategory.mutate({ categoryId: item.id, params: { published: isPublished, featured: false } });

    setIsFeatured(false);
  };

  let initialValues;

  if (data) {
    initialValues = { ...data };
  }

  return (
    <>
      <Container>
        <FullWidthBar>
          <Typography variant="h5">Featured Categories</Typography>
          <StyledSearchField
            searchString={search ?? ''}
            onSearch={(search: string) => {
              debouncedSearchFn(search);
            }}
          />
          <CreateButton
            variant="contained"
            onClick={() => setModalIsOpen(true)}
            disabled={featuredCategories.length === Defaults.MAXIMUM_FEATURED_CATEGORIES_LENGTH}
          >
            Add Featured Category
          </CreateButton>
        </FullWidthBar>

        <TableContainer sx={{ backgroundColor: 'white' }}>
          <Table aria-labelledby="tableTitle">
            <TableHead>
              <PointerTableRow>
                {columns.map((column) => (
                  <TableCell key={column.name}>
                    <TableSortLabel
                      active={sortObj.column === column.name}
                      direction={sortObj.direction}
                      onClick={() => {
                        const isAsc = sortObj.column === column.name && sortObj.direction === 'asc';
                        setNewSort(column.name, isAsc ? 'desc' : 'asc');
                      }}
                      component="a"
                    >
                      {column.text}
                      {sortObj.column === column.name ? (
                        <Box component="span" sx={visuallyHidden}>
                          {sortObj.direction === 'desc' ? 'sorted descending' : 'sorted ascending'}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </PointerTableRow>
            </TableHead>
            <TableBody>
              {isLoadingCategoryFeaured && (
                <TableRow>
                  <TableCell colSpan={3}>
                    <Spinner />
                  </TableCell>
                </TableRow>
              )}
              {!isLoadingCategoryFeaured &&
                !errorCategoryFeatured &&
                featuredCategories &&
                featuredCategories.map((row: any, index: number) => {
                  return (
                    <StyledTableRow key={index}>
                      <TableCell>{row.title}</TableCell>
                      <TableCell>{row.topicCount}</TableCell>
                      <TableCell>{row.postCommentCount}</TableCell>
                      <TableCell>{row.postViewCount}</TableCell>
                      <TableCell>
                        <ConfirmDialog
                          trigger={(open) => (
                            <Button onClick={open} type="button" style={{ textTransform: 'capitalize' }}>
                              Remove
                            </Button>
                          )}
                          title="Remove Featured Category"
                          description="Are you sure you want to remove this as featured category?"
                          cancelBtnText={'Cancel'}
                          submitBtnText={'Submit'}
                          onConfirm={async (close) => {
                            await handleRemoveFeaturedCategory(row);
                            close();
                          }}
                        ></ConfirmDialog>
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              {featuredCategories.length === 0 && (
                <StyledTableRow>
                  <TableCell colSpan={5} style={{ textAlign: 'center' }}>
                    No featured categories records found.
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
      <Modal uniqueId={'add-featured-categories'} isOpen={modalIsOpen} handleClose={() => setModalIsOpen(false)}>
        <Formik initialValues={initialValues} validateOnBlur={true} onSubmit={handleSubmit}>
          {({ values, errors, touched, setFieldValue }) => (
            <FormContainer sx={{ padding: '0 !important' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectContainer>
                    <SelectField
                      isMulti={false}
                      label="Select Featured Categories"
                      value={values.unfeaturedCategories}
                      options={unFeaturedCategories}
                      onChange={(e: CategoryUpdateDTO) => setFieldValue('unfeaturedCategories', e)}
                      placeholder="Select Featured Category"
                      changeOptionLabel="title"
                      changeOptionValue="id"
                      disabled={isSubmitting}
                      required={!(touched.unfeaturedCategories && !!errors.unfeaturedCategories)}
                    />
                    {touched.unfeaturedCategories && !!errors.unfeaturedCategories && (
                      <SelectErrorMessage>{errors.unfeaturedCategories}</SelectErrorMessage>
                    )}
                  </SelectContainer>
                </Grid>
              </Grid>
              <ButtonContainer>
                <Buttons>
                  <SubmitButton type="submit" disabled={isSubmitting} loading={isSubmitting} variant="contained">
                    Save
                  </SubmitButton>
                  <Button onClick={() => setModalIsOpen(false)} type="button">
                    Cancel
                  </Button>
                </Buttons>
              </ButtonContainer>
            </FormContainer>
          )}
        </Formik>
      </Modal>
    </>
  );
};

const Container = styled('div')({
  padding: '20px',
  backgroundColor: '#eee',
  flex: 1,
  display: 'flex',
  alignItems: 'stretch',
  flexDirection: 'column',
});

const FullWidthBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  margin: '8px 0',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const PointerTableRow = styled(TableRow)({
  cursor: 'pointer',
});

const StyledTableRow = muiStyled(PointerTableRow)(({ theme }) => ({
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledSearchField = styled(SearchField)({
  marginLeft: 'auto',
});

const SelectContainer = styled('div')({
  marginTop: '10px',
  marginBottom: '20px',
  width: '320px',
});

const SelectErrorMessage = styled('p')({
  marginTop: '3px',
  marginLeft: '14px',
  marginBottom: '0px',
  marginRight: '14px',
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'],
  fontWeight: '400',
  fontSize: '0.75rem',
  color: '#d32f2f',
  lineHeight: '1.66',
});

const ButtonContainer = styled('div')({
  marginTop: '20px',
  alignSelf: 'stretch',
  flex: 1,
  display: 'flex',
  justifyContent: 'space-between',
});

const Buttons = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',

  '& > *': {
    margin: '5px',
  },
});

export default FeaturedCategoryList;
