import { RouteProps, Outlet, Navigate } from 'react-router';

import { GuardFunc } from '../types/types';

type ProtectedRouteProps = RouteProps & {
  guards?: Array<GuardFunc>;

  // Will use to redirect when all guards failed to activate
  // By default it will redirect to `/`
  redirectTo?: string;
};

function ProtectedRoute(props: ProtectedRouteProps) {
  const { guards = [], redirectTo = '/', children } = props;

  const isAllowed = guards.some((guard) => guard().canActivate());
  if (!isAllowed) {
    return <Navigate to={redirectTo} />;
  }

  return children ? children : <Outlet />;
}

export default ProtectedRoute;
