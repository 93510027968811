import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getUsersByPage = async (page: number, sort: string, search?: string) => {
  const [response, bodyOrError] = await Api.get(`users`, {
    page,
    sort,
    search
  });

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetUsersByPage = ({ page, sort, search = '' }: { page: number; sort: string; search?: string }) =>
  useQuery(['getUsersByPage', page, sort, search], () => getUsersByPage(page, sort, search), { initialData: [] });
