import { useMutation, useQueryClient } from 'react-query';

import Api from '../../api/api';

export const approveTopic = async (topicId: string) => {
  const [response, bodyOrError] = await Api.patch(`topics/${topicId}/publish`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useApproveTopic = () => {
  const queryClient = useQueryClient();

  return useMutation(
    'approveTopic',
    (data: { topicId: string }) => {
      return approveTopic(data.topicId);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['suggestCategoryTopics']);
      },
    },
  );
};
