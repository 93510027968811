import { useMutation, useQueryClient } from 'react-query';

import { PostReport } from '../../../types/PostReport';
import Api from '../../api/api';

export const deleteReportedPost = async (reportPostId: string) => {
  const [response, bodyOrError] = await Api.delete(`posts/${reportPostId}/reported`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useDeleteReportedPostById = () => {
  const queryClient = useQueryClient();

  return useMutation<PostReport, Error, string>('deleteReportedPost', deleteReportedPost, {
    onSuccess: () => {
      queryClient.invalidateQueries('reportedPosts');
    },
  });
};
