const API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL || 'http://localhost:3003/api/';

export enum HttpStatusCode {
  OK = 200,
  CREATED = 201,
  NO_CONTENT = 204,
  UNAUTHORIZED = 401,
  FORBIDDEN = 403,
  NOT_FOUND = 404,
  INTERNAL_SERVER_ERROR = 500,
}

export const Defaults = {
  ACCESS_TOKEN_COOKIE_KEY: 'accessToken',
  REFRESH_TOKEN_COOKIE_KEY: 'refreshToken',
  ACCESS_TOKEN_HEADER_KEY: 'Authorization',
  LIST_PAGE_SIZE: 100,
  MEDIA_BREAKPOINTS: {
    xs: 600,
    sm: 1024,
    md: 1440,
    lg: 1920,
  },
  MEDIA_DIMENSIONS: [
    {
      width: 250,
      height: 250,
      key: 'smallUrl',
    },
    {
      width: 640,
      height: 480,
      key: 'mediumUrl',
    },
    {
      width: 800,
      height: 600,
      key: 'default',
    },
    {
      width: 1024,
      height: 768,
      key: 'largeUrl',
    },
  ],
  SEARCH_DEBOUNCE_MS: 500,
  MINIMUM_PASSWORD_LENGTH: 8,
  MINIMUM_ROLES_LENGTH: 1,
  MAXIMUM_FEATURED_CATEGORIES_LENGTH: 3,
  MEDIA_FILES_MAX_SIZE: 5_242_880,
  API_BASE_URL,
  GENERAL_API_PATHS: [
    /** Public API routes that don't require accessToken header */
    '/users/login',
  ],
  ERROR_DATA: {
    data: {
      statusCode: HttpStatusCode.INTERNAL_SERVER_ERROR,
      message: 'Unknown error',
    },
    status: 500,
    statusText: 'Unknown error',
  },
  FILE_IMAGE_SIZE: 2 * 1024 * 1024, //2MB
};

export enum ImageDimensionKey {
  SMALL,
  MEDIUM,
  DEFAULT,
  LARGE,
}

export const ImageDimensions = {
  [ImageDimensionKey.SMALL]: {
    width: 250,
    height: 250,
  },
  [ImageDimensionKey.MEDIUM]: {
    width: 640,
    height: 480,
  },
  [ImageDimensionKey.DEFAULT]: {
    width: 800,
    height: 600,
  },
  [ImageDimensionKey.LARGE]: {
    width: 1024,
    height: 768,
  },
};

export const allowedImageTypes = ['image/png', 'image/jpeg'];
