import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getCategoriesByFeatured = async (page: number, sort: string, search?: string, featured?: boolean) => {
  const [response, bodyOrError] = await Api.get(`categories`, {
    page,
    sort,
    search,
    featured,
  });

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError.data;
};

export const useGetCategoriesByFeatured = ({
  page,
  sort,
  search = '',
  featured,
}: {
  page: number;
  sort: string;
  search?: string;
  featured?: boolean;
}) =>
  useQuery(['getCategoriesByFeatured', page, sort, search, featured], () => getCategoriesByFeatured(page, sort, search, featured), {
    initialData: [],
  });
