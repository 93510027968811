import { ReactNode, useEffect } from 'react';

import { Box, Container } from '@mui/material';

interface ModalProps {
  uniqueId: string;
  children: ReactNode;
  isOpen: boolean;
  handleClose: () => void;
  closeByOverlayClick?: boolean;
}

const Modal = ({ uniqueId, children, isOpen, handleClose, closeByOverlayClick }: ModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  if (!isOpen) return null;

  return (
    <Container id={uniqueId}>
      <Box
        sx={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: 'rgba(0,0,0,0.5)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 99999,
        }}
        onClick={closeByOverlayClick ? handleClose : undefined}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            gap: '24px',
            borderRadius: '16px',
            flexDirection: 'column',
            maxWidth: ['90%', '66%', '44%', '33%'],
            px: ['25px', '50px'],
            py: ['18px', '36px'],
            alignItems: 'center',
            position: 'relative',
            display: 'flex',
            WebkitAlignItems: 'start',
          }}
          onClick={closeByOverlayClick ? (e) => e.stopPropagation() : undefined}
        >
          {children}
        </Box>
      </Box>
    </Container>
  );
};

export { Modal };
