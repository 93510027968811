import { TextField as MUITextField, TextFieldProps as MUITextFieldProps } from '@mui/material';

export const TextField = (props: MUITextFieldProps) => {
  const { className, ...rest } = props;
  return (
    <div className={className}>
      <MUITextField fullWidth {...rest} />
    </div>
  );
};
