import { useQuery } from 'react-query';

import Api from '../../api/api';

export const getReportedPostById = async (reportedPostId: string) => {
  const [response, bodyOrError] = await Api.get(`posts/${reportedPostId}/reported`);

  if (!response.ok) {
    throw bodyOrError;
  }

  return bodyOrError;
};

export const useGetReportedPostById = (reportedPostId: string) =>
  useQuery(['getReportedPostById', reportedPostId], () => getReportedPostById(reportedPostId), {
    enabled: reportedPostId !== 'new' || !!reportedPostId,
  });
